import "../styles/Terms.css";

function Terms() {
  return (
    <div className="jazz_discount_outer">
      <h2>Terms & Conditions</h2>
      <div style={{ background: "rgba(0,0,0,0.8)", padding: "20px" }}>
        <div>
          <p>
            This Agreement sets forth the legally binding terms and conditions
            governing the acquisition and use by you, the “Client”, of Cricket
            Fantasy (the “Service”).
          </p>
          <p>1. GENERAL CONDITIONS</p>
          <p>
            1.1 Cricket Fantasy is a game in which subscribers can make their
            own teams, follow their favorite leagues, and win prizes based on
            real-time results.
          </p>
          <p>
            1.1.1 The use of such content is unrestrained, there being no limit.
            The sole restriction is the storage capacity of the CLIENT's mobile
            equipment.
          </p>
          <p>
            1.2 All content on the Cricket Fantasy portal does not belong to
            Telenor and is made available by third-party developers. For this
            reason, Telenor assumes no responsibility for the Service, their
            integrity, functionality, or for any permissions granted by the
            client while accessing the content.
          </p>
          <p>
            1.3 The client is hereby made aware that, for the use of the Cricket
            Fantasy, a mobile device is required, which must be compatible.
          </p>
          <p>
            1.3.1 The client is hereby made aware that the use of Cricket
            Fantasy and its content requires an Internet connection (Wifi, GPRS,
            EDGE, 3G, 4G or other). There is no offline usage.
          </p>
          <p>
            1.4 The client hereby acknowledges and agrees that by subscribing to
            the service all charges and fees are due, regardless of the client's
            mobile equipment meeting any compatibility criteria upon
            subscription. No refunds will be issued by Telenor based on the
            client's inaptitude to access the service due to a lack of
            compatible mobile equipment.
          </p>
          <p>
            1.5 Internet access and any data traffic associated with the use of
            the service will be charged as appropriate by the client’s
            respective mobile network provider. Telenor may at its sole
            discretion exempt the user from data traffic charges associated with
            the usage of content, on a temporary basis, and the client
            acknowledges that this exemption may be terminated at any time.
          </p>
          <p>
            1.6 To subscribe to the service the client must either be of legal
            age or be assisted as appropriate by his/her parents or legal
            guardians.
          </p>
          <p>
            1.6.1 Whenever the service is accessed or purchased by minors, the
            responsibility for the access will lie with the bill payer for the
            respective mobile network provider, who will be assumed to have
            given permission to the minor to use the service. Telenor will not
            be held accountable for improper or unintended use of the Service or
            for the unintended acquisition of products. The legal
            guardian/contracting party is the sole responsible for the care and
            keeping of the mobile equipment required to use the Service.
          </p>
          <p>
            1.7 Telenor will not be held accountable for the ineptitude of the
            client in connecting to the Internet, employing hardware equipment
            or software in the use of apps, or for the availability of any
            third-party or Internet links to the service. The client
            acknowledges that Telenor gives no warranty or representation that
            the service will meet the client’s requirements or any objectives in
            particular.
          </p>
          <p>
            1.8 Telenor will not be held accountable for any form of advertising
            and/or for the content of banner ads, pop-up windows and any other
            advertising formats shown on websites and third-party apps made
            available through the service and its portals. The responsibility
            for such content lies solely on the advertiser or their legal
            representatives.
          </p>
          <p>
            1.9 The client acknowledges that given the nature of such services,
            Telenor cannot guarantee that the service will be uninterrupted or
            error-free.
          </p>
          <p>
            1.10 Telenor assumes no responsibility for any damage or loss
            incurred as a result of the client’s access to fake websites and
            portals that mimic the service’s pages and which are not governed by
            the present terms and conditions of use.
          </p>
          <p>
            1.11 The client acknowledges that under no circumstances will
            Telenor be held accountable for any damage or loss incurred as a
            result of:
          </p>
          <ul>
            {" "}
            <li>the client’s use or inability to use the service;</li>
            <li>
              the sharing of personal information to social networks in
              association with the service;
            </li>
            <li>
              unauthorized access to user data or data transmissions, or any
              adulterations of personal data as a result of unauthorized access;
            </li>
            <li>acts and behavior of third-parties upon the service;</li>
            <li>
              force majeure or fortuitous event or any acts performed by the
              client.
            </li>
          </ul>
          <p>
            1.12 Telenor will not reimburse any amounts eventually charged to
            the client under the following claims:
          </p>
          <ul>
            {" "}
            <li>
              incompatibility between hardware and software elements employed by
              the client and the service;
            </li>
            <li>
              the client’s mobile equipment not meeting the minimum requirements
              to run the service;
            </li>
            <li>misexpectations or errors attributable to the client;</li>
            <li>
              the lack of observation by the client of any policies mentioned in
              the present terms and of any instructions available in the
              service’s portals and websites;
            </li>
            <li>acts of bad faith;</li>
            <li>client’s withdrawal after the service has been granted.</li>
          </ul>
          <p>
            1.13 Even where the client is apt to use the service it is his or
            her responsibility to act in conformity with the principles and in
            observance of the rules of the service, expressly as presented,
            without warranties or other conditions not explicitly stated in the
            present terms and conditions of use. The content made available
            through the service is determined at Telenor’s discretion. All
            content is the sole responsibility of the content owner in regard to
            quality, precision, fidelity, reliability, information, opinion,
            declaration, warranties, and advertising of any sort.1.14 Telenor
            will not be held accountable for any changes or withdrawals of
            content from the service and for any outcomes of these changes. The
            client acknowledges and agrees that such changes and withdrawals are
            inherent to the service.1.14.1 Telenor will apply reasonable efforts
            in obtaining similar content to the ones withdrawn from the service.
          </p>
          <p>2. BILLING</p>
          <p>
            2.1 Clients will be charged up to the amount of 35 PKR. per week
            (get 1 day of a free trial) for as long as they remain as
            subscribers, regardless of their use of the service they are
            entitled to.
          </p>
          <p>
            2.2 Data usage charges as per the standard package plan. If the
            subscriber's account is successfully charged the subscriber is
            provided access to the store to use a content for 1 day from the
            payment date.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
