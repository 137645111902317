import React from "react";
import "../styles/Landing.css";
import logo from "../Assets/imgs/logo.webp";
import mainimg from "../Assets/imgs/main-image.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};

const Landing = (props: Props) => {
  return (
    <div className="landing-bg">
      <div className="subscrption-content ">
        <div className="subscrption-inner">
          <div className="logo">
            <img src={logo} width={172} height={96} alt="Logo" />
          </div>

          <div className="heading">
            Play, Learn, and Master English with Disney's Magical Characters
          </div>
          <div className="paid-text">
            1 day free then only Rs.50/week (incl of tax)
          </div>
          <div className="button">
            {props.showError && <p className="error">{props.errorMsg}</p>}
            {props.phoneNo && (
              <>
                {props.showPlay ? (
                  <>
                    {props.confirmLoader ? (
                      <button className="disable-btn">Confirm</button>
                    ) : (
                      <button onClick={() => props.onSubscribePressed()}>
                        Confirm
                      </button>
                    )}
                  </>
                ) : (
                  <button onClick={props.onHandlePlay}>START</button>
                )}
              </>
            )}
          </div>
          <div className="text">
            I agree to weekly recurring charges deduction from my mobile
            balance. Free trial is for new users only
          </div>
          <a className="term" href="terms">
            Terms & Conditions
          </a>
          <div className="main-image">
            <img src={mainimg} width={375} height={228} alt="Main Image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
